.btn-register {
  background-color: #BB0000;
  color: white;
  border: 0;
  margin-top: 5%;
}
.btn-register:focus {
  background-color: #444343;
  color: white;
  border: 0;
  margin-top: 5%;
}

.white {
  background-color: transparent;
  border: none;
  text-decoration: none;
}
.white:hover {
  background-color: transparent;
  border: none;
  text-decoration: underline;
}
.white:active {
  background-color: transparent;
  border: none;
}
.notifyIcon {
  font-size: 2rem;
  margin-left: 1rem;
}
.notifyText {
  font-size: 1.2rem;
  font-weight: bold;
  margin-left: 1rem;
}
.divNotify {
  flex-direction: row;
}
.center {
  margin: auto;
  width: 18%;
}

.right {
  float: right;
  max-width: 10%;
  min-width: 2%;
  position: relative;
  right: 0;
  margin-top: 100px;
}
.rowInfo {
  margin-top: 10%;
}

li {
  font-size: 1rem;
  font-weight: 400;
}

.materialTextField {
  margin-top: 5%;
  background-color: white;
  border-radius: 5px;
}
